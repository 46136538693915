import React, { useState } from 'react'
import { postMyCollectionsCreate } from 'apis/private/collection'
import { ConnectorProps as CollectionProps } from 'connectors/Collection'
import CollectionConnector from 'connectors/Collection'
import CollectionForm from '../Form/Create'

type Props = CollectionProps & {
  addCollection: (...args: Array<any>) => any
  artworkId?: number
  closeModal: () => void
  onSuccess?: (...args: Array<any>) => any
}

const CreateCollectionsContent = (props: Props) => {
  const { addCollection, addMessage, closeModal, isAdmin, onSuccess } = props
  const [isSubmitting, handleIsSubmitting] = useState(false)

  return (
    <CollectionForm
      isModal={!isAdmin}
      isAdmin={isAdmin}
      isSubmitting={isSubmitting}
      closeModal={closeModal}
      createCollection={async (formData) => {
        handleIsSubmitting(true)
        const { success, payload } = await postMyCollectionsCreate(formData)

        if (!success) {
          addMessage({
            messages: ['Problem creating collection'],
            type: 'error',
          })
          handleIsSubmitting(false)
          return
        }

        addMessage({
          messages: ['New Collection Created. Browse art to add to your collection.'],
          type: 'success',
        })
        addCollection(payload)

        if (onSuccess) {
          onSuccess(payload, formData)
        }

        handleIsSubmitting(false)
        closeModal()
      }}
    />
  )
}

export default CollectionConnector(CreateCollectionsContent)
