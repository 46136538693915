import React, { ReactNode } from 'react'
import { filterObject } from 'lib/helpers'
import { CheckboxV2Component, Icon, CheckMark } from './Styles'

type CheckboxV2Props = {
  checked: boolean
  readOnly: boolean
  text: string
  children: ReactNode
  customStyles: Record<string, any>
}

const CheckboxV2 = (props: CheckboxV2Props) => {
  const { checked, readOnly, text, children, customStyles = {} } = props

  return (
    <CheckboxV2Component readOnly={!!readOnly} style={{ ...customStyles }}>
      <div data-type='checkbox-text'>
        <input
          onChange={
            () => {}
            /* React Warning - when no Function */
          }
          {...filterObject(props, ['children'])}
          type='checkbox'
          aria-checked={checked}
        />

        <Icon aria-hidden='true'>{checked && <CheckMark checked={!!checked} />}</Icon>

        {text && <p className='defaultTypographyCtaSmall'>{text}</p>}
      </div>

      {children || ''}
    </CheckboxV2Component>
  )
}

export default CheckboxV2
