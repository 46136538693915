import React, { FC, useState } from 'react'
import { LoadingSpinner } from 'styles/loader'
import HeroConnector, { ConnectorProps as HeroProps } from '../connectors/Hero'
import { uploadHeroImage } from 'apis/private/heroImage'
import { FrameIcon, PencilIcon } from './styles'
import { updateImagePathBasedOnHostName } from '../helpers'

const uploadHeroImageApi = async ({ imageFile, callbackFunc, addMessage }: Record<string, any>) => {
  const heroImageApi = await uploadHeroImage(imageFile)

  if (heroImageApi.success) {
    const { largeImage, smallImage } = heroImageApi.payload
    callbackFunc({
      largeHeroImage: largeImage,
      smallHeroImage: smallImage,
    })
  } else {
    const { errorMessage } = heroImageApi.payload
    let flashErrorMessage = 'Cover photo could not be uploaded'

    if (errorMessage) {
      flashErrorMessage = errorMessage
    }

    addMessage(flashErrorMessage, 'error')
    callbackFunc({
      isLoading: false,
    })
  }
}

const Hero: FC<HeroProps> = (props) => {
  const { heroImages, addMessage, sessionUserID, accountUserID } = props
  const { largeImage, smallImage } = heroImages
  const [state, handleState] = useState({
    isLoading: false,
    largeHeroImage: largeImage,
    smallHeroImage: smallImage,
  })
  const { isLoading, largeHeroImage, smallHeroImage } = state
  const myOwnAccount = sessionUserID === accountUserID
  const isHeroImgPresent = largeHeroImage || smallHeroImage

  const onFileChanged = async (e: React.SyntheticEvent<HTMLInputElement>) => {
    e.preventDefault()

    if (e.target.files && e.target.files.length) {
      const { files } = e.target
      const imageFile = files[0]
      handleState((prevState) => ({ ...prevState, isLoading: true }))
      uploadHeroImageApi({
        imageFile,
        callbackFunc: (data: Record<string, any>) => {
          handleState((prevState) => ({ ...prevState, isLoading: false, ...data }))
        },
        addMessage,
      })
    }
  }

  return (
    <div data-section='cover-photo-wrapper'>
      <div data-section='cover-photo'>
        <div data-section='hero-image-section'>
          {isLoading && (
            <>
              <div data-type='overlay' />
              <p data-type='loader-text'> Please wait while your image is uploaded... </p>
              <LoadingSpinner data-type='loader' />
            </>
          )}
          {!isHeroImgPresent && myOwnAccount ? (
            <div
              data-type='upload-section'
              role='button'
              tabIndex={0}
              onClick={(ev) => (ev.target.value = '')}
              onKeyDown={(ev) => (ev.target.value = '')}
            >
              <FrameIcon />
              <p data-type='upload-label'> add cover photo </p>
              <input
                data-type='upload-input'
                type='file'
                accept='image/*'
                onChange={onFileChanged}
                aria-label='hidden'
              />
            </div>
          ) : (
            ''
          )}
          {isHeroImgPresent && (
            <div data-type='hero-image'>
              <picture data-type='image'>
                <source
                  type='image/jpg'
                  media='(max-width:768px)'
                  srcSet={updateImagePathBasedOnHostName(smallHeroImage)}
                />
                <img
                  width='1440'
                  height='260'
                  src={updateImagePathBasedOnHostName(largeHeroImage)}
                  itemProp='image' // className='lazyload'
                  // loading='lazy'
                  // data-src={largeHeroImage}
                />
              </picture>
              {/* You only need the NOSCRIPT for lazy loading
            <noscript>
             <img width='1440' height='260' src={largeHeroImage} itemProp='image' />
            </noscript> */}
            </div>
          )}
        </div>
        {isHeroImgPresent && myOwnAccount ? (
          <div
            data-type='edit-hero-section'
            role='button'
            tabIndex={0}
            onClick={(ev) => (ev.target.value = '')}
            onKeyDown={(ev) => (ev.target.value = '')}
          >
            <input
              data-type='upload-input'
              type='file'
              accept='image/*'
              onChange={onFileChanged}
              aria-label='hidden'
            />
            <div data-type='edit-hero-icon'>
              <PencilIcon />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default HeroConnector(Hero)
