type Response = {
  messages?: Array<string>
  type?: 'success' | 'error'
  payload?: Record<string, any>
}

// import { addArtworkToCollectionApi, createNewCollection, fetchCollections } from 'apis/collection'
export const fetchMyCollection = async (): Promise<Response> => {
  const { fetchMyCollections } = await import('apis/private/collection')
  const json = await fetchMyCollections()
  const { success, payload } = json

  if (!success) {
    return {
      messages: ['Problem fetching collections for artwork'],
      type: 'error',
    }
  }
  return payload
}

export const fetchCollectionByArtworkId = async (artworkId: number): Promise<Response> => {
  const { fetchMyCollectionsForArtwork } = await import('apis/private/collection')
  const json = await fetchMyCollectionsForArtwork(artworkId)
  const { success, payload } = json

  if (!success) {
    return {
      messages: ['Problem fetching collections for artwork'],
      type: 'error',
    }
  }
  return payload
}

type PostParams = {
  artworkId: number
  userCollectionId: number
  action: 'ADD' | 'REMOVE'
}

export const updateArtworkInMyCollection = async ({
  artworkId,
  userCollectionId,
  action,
}: PostParams): Promise<Response> => {
  const { putArtworkInMyCollection, deleteArtworkInMyCollection } = await import(
    'apis/private/collection'
  )
  const apiEndpoint = action === 'REMOVE' ? deleteArtworkInMyCollection : putArtworkInMyCollection
  const json = await apiEndpoint({
    artworkId,
    userCollectionId,
  })
  const { success } = json

  if (!success) {
    return {
      messages: [`Problem ${action === 'REMOVE' ? 'removing' : 'adding'} artwork to collection`],
      type: 'error',
    }
  }

  // AlgoliaActions.EVENT({
  //   eventName: action.payload.remove ? 'remove from collection' : 'added to collection',
  //   eventType: 'view',
  //   objectIDs: [artworkId],
  // })
  window.dataLayer.push({
    event: action === 'REMOVE' ? 'removed from collection' : 'added to collection',
  })
  // TODO
  return {
    type: 'success',
  }
}
