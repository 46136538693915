import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react'
import CollectionConnector, { ConnectorProps as CollectionProps } from 'connectors/Collection'
import AuthenticationConnect, {
  ConnectorProps as AuthenticationProps,
} from 'connectors/Authentication'
import Tooltip from 'components/Tooltip'
import { useStore } from 'react-redux'
import { Actions as FavoriteActions } from 'actions/user/favorite'
import Layout from './Layout'
import {
  fetchMyCollection,
  fetchCollectionByArtworkId,
  updateArtworkInMyCollection,
} from './helpers'
import { PlusIcon } from './styles'

const COLLECTION_STATE = {
  isSetup: false,
  // Do I have API data
  artworkList: [], // Array<COLLECTIONID> belonging to Artwork
}
type Props = CollectionProps &
  AuthenticationProps & {
    artworkId: number
    handleOpenCreateCollectionsModal: () => void
    dataStyle?: string
    customAnimationStyle?: Record<string, any>
    tooltipPosition?: 'left' | 'right' | 'top' | 'bottom'
  }

export type CollectionTooltipApi = {
  addArtworkToCollection: (payload: Record<string, any>) => Promise<void>
}

const CollectionTooltip = forwardRef<CollectionTooltipApi, React.PropsWithChildren<Props>>(
  (props, ref) => {
    const {
      addMessage,
      artworkId,
      children,
      collections,
      collectionsLoaded,
      setCollections,
      isAuthenticated,
      openModal,
      selectedFavorite,
      handleOpenCreateCollectionsModal,
      dataStyle,
      customAnimationStyle,
      tooltipPosition = 'bottom',
    } = props
    const [isOpen, handleIsOpen] = useState(false)
    const [isSetup, handleIsSetup] = useState(false)
    const [artworkList, handleArtworkList] = useState(COLLECTION_STATE)
    const [continueAfterLogin, handlecontinueAfterLogin] = useState(false)
    const store = useStore()

    const tooltipRef = useRef<HTMLDivElement | null>(null)

    // Event listener to close the tooltip if clicked outside
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
          handleIsOpen(false)
        }
      }

      document.addEventListener('mousedown', handleClickOutside)

      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [])

    const loadUsersCollections = async () => {
      const myCollections = await fetchMyCollection()

      if (myCollections.type === 'error') {
        addMessage(myCollections)
        return
      }

      setCollections(myCollections)
    }

    const fetchData = async (): Promise<void> => {
      // Load the Collection List TO/FROM redux store
      if (!collectionsLoaded) {
        await loadUsersCollections()
      }

      const artworkCollectionList = await fetchCollectionByArtworkId(artworkId)

      if (artworkCollectionList.type === 'error') {
        addMessage(artworkCollectionList)
        return
      }

      // Flatten Artworks into a ARRAY to easily search against
      const myArtworkList = artworkCollectionList.map(({ userCollectionId }) => userCollectionId)
      handleArtworkList(myArtworkList)
      handleIsSetup(true)
    }

    const addArtworkToCollection = async (payload: Record<string, any>) => {
      // The AA Collection and regular collection endpoints send pack different payloads.
      const collectionId = payload.collection_id
        ? payload.collection_id
        : payload.data.id_user_collection
      const response = await updateArtworkInMyCollection({
        artworkId,
        userCollectionId: collectionId,
        action: 'ADD',
      })

      if (response.type === 'error') {
        addMessage([response])
        return
      }

      await loadUsersCollections()
      // Refetch Artworks Collection Values
      handleIsSetup(false)
    }

    useEffect(() => {
      if (isOpen && !isSetup) {
        fetchData()
      }
    }, [isOpen, isSetup])
    useEffect(() => {
      // Follow login modal complete with opening of the collection modal
      if (continueAfterLogin) {
        handlecontinueAfterLogin(false)
        handleIsOpen(true)
      }
    }, [isAuthenticated])
    useEffect(() => {
      // If user has started a Favorite action, halt continuation of action here
      if (selectedFavorite !== null) {
        handlecontinueAfterLogin(false)
      }
    }, [selectedFavorite])

    const handleToggleOpen = (): void => {
      // If user clicks on + icon and not logged in, open login modal
      window.dataLayer.push({
        event: 'add to a collection',
      })

      if (!isAuthenticated) {
        openModal('login')
        handlecontinueAfterLogin(true)
        // Null the selectedFavorite in Redux so a Favorite action
        // doesn't complete once login is successful
        store.dispatch(FavoriteActions.SELECT_ARTWORK(null))
      } else {
        handleIsOpen(true)
      }
    }

    const handleCloseTooltip = () => {
      handleIsOpen(false)
    }

    useImperativeHandle(ref, () => ({
      addArtworkToCollection,
    }))

    return (
      <Tooltip
        alignment='center'
        clickable
        color='white'
        dropShadow
        isVisible={isOpen}
        position={tooltipPosition}
        dataStyle={dataStyle}
        customAnimationStyle={customAnimationStyle}
        tip={
          <Layout
            containerRef={tooltipRef}
            addMessage={addMessage}
            artworkId={artworkId}
            collectionList={collections}
            artworkList={artworkList}
            isSetup={isSetup}
            handleOpenCreateCollectionsModal={handleOpenCreateCollectionsModal}
            onCloseTooltip={handleCloseTooltip}
          />
        }
        toggleCallback={handleToggleOpen}
      >
        {/* BUTTON to CLICK */}
        {children || <PlusIcon />}
      </Tooltip>
    )
  }
)

export default AuthenticationConnect(CollectionConnector(CollectionTooltip))
