import easelAPI, {
  getOptions,
  postOptions,
  putOptions,
  deleteOptions,
  EaselResponse,
} from 'apis/helpers/easelAPI'
import legacyAPI, { createFormData, LegacyResponse } from 'apis/helpers/legacyAPI'

export async function fetchMyCollections(): Promise<EaselResponse> {
  const options = getOptions
  const json = await easelAPI.fetchValidateLoginJSON({
    name: 'fetchMyCollections',
    options,
    url: '/my-collections',
  })
  return json
}

export async function fetchMyCollectionsForArtwork(artworkId: number): Promise<EaselResponse> {
  const options = getOptions
  const json = await easelAPI.fetchValidateLoginJSON({
    name: 'fetchMyCollectionsForArtwork',
    options,
    url: `/my-collections/artwork/${artworkId}`,
  })
  return json
}

export async function postMyCollectionsCreate(
  formData: Record<string, any>
): Promise<EaselResponse> {
  const options = { ...postOptions, body: JSON.stringify(formData) }
  const json = await easelAPI.fetchValidateLoginJSON({
    name: 'postMyCollectionsCreate',
    options,
    url: `/my-collections/create`,
  })
  return json
}

export async function putArtworkInMyCollection({
  artworkId,
  userCollectionId,
}: Record<string, any>): Promise<EaselResponse> {
  const options = putOptions
  const json = await easelAPI.fetchValidateLoginJSON({
    name: 'putArtworkInMyCollection',
    options,
    url: `/my-collections/collection/${userCollectionId}/artwork/${artworkId}`,
  })
  return json
}

export async function deleteArtworkInMyCollection({
  artworkId,
  userCollectionId,
}: Record<string, any>): Promise<EaselResponse> {
  const options = deleteOptions
  const json = await easelAPI.fetchValidateLoginJSON({
    name: 'deleteArtworkInMyCollection',
    options,
    url: `/my-collections/collection/${userCollectionId}/artwork/${artworkId}`,
  })
  return json
}

export async function deleteCollection(idUserCollection: number): Promise<EaselResponse> {
  const options = {
    ...deleteOptions,
    body: JSON.stringify({
      idUserCollection,
    }),
  }
  const json = await easelAPI.fetchValidateLoginJSON({
    name: 'deleteCollection',
    options,
    url: '/my-collection/delete',
  })
  return json
}

export async function duplicateCollection(idUserCollection: number): Promise<EaselResponse> {
  const options = {
    ...postOptions,
    body: JSON.stringify({
      idUserCollection,
    }),
  }
  const json = await easelAPI.fetchValidateLoginJSON({
    name: 'duplicateCollection',
    options,
    url: '/my-collection/duplicate',
  })
  return json
}

// LEGACY API's
// TODO - OLD LEGACY - DELETE - Need all NEW apis, before we can
export async function fetchCollectionTooltipApi(): Promise<LegacyResponse> {
  const json = await legacyAPI.fetchJSON({
    name: 'fetchCollectionTooltipApi',
    url: '/collections/get-art-collections',
    defaultResponse: {
      result: false,
      collections: [],
    },
  })
  return json
}

export async function fetchArtworkCollectionTooltipApi(artworkId: number): Promise<LegacyResponse> {
  const json = await legacyAPI.fetchJSON({
    name: 'fetchArtworkCollectionTooltipApi',
    url: `/collections/get-collections-art-is-in/${artworkId || 0}`,
  })
  return json
}

export async function addArtworkToCollectionApi({
  artworkId,
  collectionId,
  remove,
}: Record<string, any>): Promise<LegacyResponse> {
  // /collections/add-to-collection-by-id-user-art
  const url = remove
    ? `/collections/remove-art-from-collection/${artworkId}/${collectionId}`
    : `/collections/add-art-to-collection?id_user_art=${artworkId}&collection_id=${collectionId}`
  const json = await legacyAPI.fetchJSON({
    name: 'addToCollectionTooltipApi',
    url: url,
    defaultResponse: {
      result: false,
    },
  })
  return json
}

export async function searchCollectionTooltipApi(search: string): Promise<LegacyResponse> {
  const json = await legacyAPI.fetchJSON({
    name: 'searchCollectionTooltipApi',
    url: `/collections/get-art-collections?title_search=${search}`,
  })
  return json
}

export async function createNewCollection(
  formPayload: Record<string, any>
): Promise<LegacyResponse> {
  const formData = createFormData(formPayload)
  const formOptions = { method: 'POST', body: formData }
  const json = await legacyAPI.fetchJSON({
    name: 'createNewCollection',
    url: '/collections/create',
    formOptions,
  })
  return json
}
