import React, { PureComponent } from 'react'
import styled, { withTheme } from 'styled-components'
import { space } from 'styled-system'
import Animation from 'components/Animations/Animation'

const caretSize = 8
const duration = 250
const defaultStyles = {
  willChange: 'transform, opacity',
  position: 'absolute',
  zIndex: '10',
  cursor: 'auto',
}
const animation = {
  bottom: {
    from: {
      opacity: 0,
      transform: 'translate3d(-50%, 20%, 0)',
    },
    enter: {
      opacity: 1,
      transform: 'translate3d(-50%, 0%, 0)',
    },
    leave: {
      opacity: 0,
      transform: 'translate3d(-50%, 20%, 0)',
    },
  },
  top: {
    from: {
      opacity: 0,
      transform: 'translate3d(-50%, -20%, 0)',
    },
    enter: {
      opacity: 1,
      transform: 'translate3d(-50%, 0%, 0)',
    },
    leave: {
      opacity: 0,
      transform: 'translate3d(-50%, -20%, 0)',
    },
  },
  left: {
    from: {
      opacity: 0,
      transform: 'translate3d(-20%, -50%, 0)',
    },
    enter: {
      opacity: 1,
      transform: 'translate3d( 0%, -50%,0)',
    },
    leave: {
      opacity: 0,
      transform: 'translate3d(-20%, -50%, 0)',
    },
  },
  right: {
    from: {
      opacity: 0,
      transform: 'translate3d(20%, -50%, 0)',
    },
    enter: {
      opacity: 1,
      transform: 'translate3d( 0%, -50%,0)',
    },
    leave: {
      opacity: 0,
      transform: 'translate3d(20%, -50%, 0)',
    },
  },
}
const alignmentStyle = {
  left: {
    right: '125%',
    top: '50%',
  },
  right: {
    left: '125%',
    top: '50%',
  },
  top: {
    bottom: '125%',
    left: '50%',
  },
  bottom: {
    top: '125%',
    left: '50%',
  },
}

const positionBottom = (props) => `
  &:after {
    border-left: ${caretSize}px solid transparent;
    border-right: ${caretSize}px solid transparent;
    border-bottom: ${caretSize}px solid ${props.color};
    top: -${caretSize - 1}px;
  }
`

const positionTop = (props) => `
  &:after {
    border-left: ${caretSize}px solid transparent;
    border-right: ${caretSize}px solid transparent;
    border-top: ${caretSize}px solid ${props.color};
    bottom: -${caretSize - 1}px;
  }
`

const positionLeft = (props) => `
  &:after {
    border-top: ${caretSize}px solid transparent;
    border-bottom: ${caretSize}px solid transparent;
    border-left: ${caretSize}px solid ${props.color};
    right: -${caretSize - 1}px;
  }
`

const positionRight = (props) => `
  &:after {
    border-top: ${caretSize}px solid transparent;
    border-bottom: ${caretSize}px solid transparent;
    border-right: ${caretSize}px solid ${props.color};
    left: -${caretSize - 1}px;
  }
`

const alignmentCenter = (props) => {
  if (props.position === 'top' || props.position === 'bottom') {
    return `
      &:after {
        left: 50%;
        transform: translateX(-50%);
      }
    `
  }

  return `
      &:after {
        top: 50%;
        transform: translateY(-50%);
      }
    `
}

const alignmentLeft = `
  &:after {
    right: ${caretSize}px;
  }
`
const alignmentRight = `
  &:after {
    left: ${caretSize}px;
  }
`
const alignmentTop = `
  &:after {
    bottom: ${caretSize}px;
  }
`
const alignmentBottom = `
  &:after {
    top: ${caretSize}px;
  }
`
export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`
export const Item = styled.div`
  ${(props) => (props.dataStyle === 'flex' ? 'display: flex;' : '')};
  [data-type='reason-to-buy'] {
    top: 0;
  }
`
export const Box = styled.div`
  ${space};
  pointer-events: none;
  display: inline-block;
  background: ${(props) => props.color};
  backface-visibility: hidden;
  ${(props) => props.visible && 'pointer-events: initial;'};

  @media (min-width: 1000px) {
    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      backface-visibility: hidden;
    }
  }

  ${(props) => props.position === 'bottom' && positionBottom(props)};
  ${(props) => props.position === 'top' && positionTop(props)};
  ${(props) => props.position === 'left' && positionLeft(props)};
  ${(props) => props.position === 'right' && positionRight(props)};

  ${(props) => props.alignment === 'left' && alignmentLeft};
  ${(props) => props.alignment === 'right' && alignmentRight};
  ${(props) => props.alignment === 'top' && alignmentTop};
  ${(props) => props.alignment === 'bottom' && alignmentBottom};

  ${(props) => props.alignment === 'center' && alignmentCenter(props)};
  ${(props) => props.dropShadow && `box-shadow: 10px 10px 25px -5px rgba(0, 0, 0, 0.5);`};
`
type Props = {
  alignment?: string
  children: React.ReactElement<React.ComponentProps<any>, any>[]
  clickable?: boolean
  closeTip?: boolean
  color?: string
  dropShadow?: boolean
  interval?: number
  isVisible?: boolean
  position?: string
  theme: {
    colors: {
      grayDark: ''
    }
    breakpoints: Array<number>
    defaultFontSize: number
  }
  tip: string
  toggleCallback?: (arg0: boolean) => void
  dataStyle?: string
  customAnimationStyle?: Record<string, any>
}
type State = {
  isVisible: boolean
}

class Tooltip extends PureComponent<Props, State> {
  container: {
    current: HTMLElement | null | undefined
  }
  wrapper: {
    current: HTMLElement | null | undefined
  }
  interval: number = 300
  timer: TimeoutID | null | undefined = null

  constructor(props: Props) {
    super(props)
    this.container = React.createRef()
    this.wrapper = React.createRef()

    if (props.interval) {
      this.interval = props.interval
    }

    this.state = {
      isVisible: false,
    }
  }

  componentDidMount() {
    const { clickable, theme } = this.props

    if (document.body && document.body.clientWidth <= theme.breakpoints[1]) {
      this.setupClick()
    } else {
      clickable ? this.setupClick() : this.setupMouse()
    }
  }

  componentWillUnmount() {
    const containerElement = this.container.current

    if (containerElement) {
      containerElement.removeEventListener('click', this.toggleTip)
    }

    const wrapperElement = this.wrapper.current

    if (wrapperElement) {
      wrapperElement.removeEventListener('mouseenter', this.handleMouseEnter)
      wrapperElement.removeEventListener('mouseleave', this.handleMouseLeave)
    }
  }

  setupClick = () => {
    this.interval = 0
    const containerElement = this.container.current

    if (containerElement) {
      containerElement.addEventListener('click', this.toggleTip)
    }
  }
  setupMouse = () => {
    const wrapperElement = this.wrapper.current

    if (wrapperElement) {
      wrapperElement.addEventListener('mouseenter', this.handleMouseEnter)
      wrapperElement.addEventListener('mouseleave', this.handleMouseLeave)
    }
  }
  toggleTip = () => {
    const { toggleCallback } = this.props

    if (toggleCallback) {
      toggleCallback(!this.state.isVisible) // Send Back what it will become
    }

    this.state.isVisible ? this.handleMouseLeave() : this.handleMouseEnter()
  }
  handleMouseEnter = () => {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }

    this.setState({
      isVisible: true,
    })
  }
  handleMouseLeave = () => {
    this.timer = setTimeout(
      () =>
        this.setState({
          isVisible: false,
        }),
      this.interval
    )
  }

  render() {
    const {
      theme,
      children,
      clickable,
      dropShadow,
      tip = 'Tool Tip',
      position = 'bottom',
      alignment = 'center',
      color,
      dataStyle,
      customAnimationStyle = {},
    } = this.props
    const { isVisible } = this.state
    const animationProps = {
      duration,
      defaultStyles: { ...defaultStyles, ...alignmentStyle[position] },
      isVisible,
      ...animation[position],
      customAnimationStyle,
    }

    if (clickable && !this.props.isVisible) {
      this.handleMouseLeave()
    }

    return (
      <Wrapper ref={this.wrapper}>
        <Item dataStyle={dataStyle} ref={this.container}>
          {children}
        </Item>

        <Animation {...animationProps}>
          <Box
            visible={isVisible ? true : null}
            position={position}
            alignment={alignment}
            dropShadow={dropShadow ? true : null}
            color={color || theme.colors.grayDark}
            p={1}
          >
            {tip}
          </Box>
        </Animation>
      </Wrapper>
    )
  }
}

export default withTheme(Tooltip)
