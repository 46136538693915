import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { Actions as FollowActions } from 'actions/user/follow'
import { getFollow } from 'selectors/user/follow'
import { isFavorited } from 'selectors/user/favorite'
import { isAuthenticated, getUserID } from 'selectors/user/session'
import type { Follow } from 'types/User/Follow'
import 'types/User/Follow'
const mapStateToProps = createStructuredSelector({
  favorited: (state, ownProps) => isFavorited(ownProps.artId)(state),
  following: getFollow,
  authenticated: isAuthenticated,
  userID: getUserID,
})

const mapDispatchToProps = (dispatch) => ({
  followUser: (follow: { userID: number; targetUserId: number; following: boolean }) =>
    dispatch(FollowActions.UPDATE(follow)),
})

export type ConnectorProps = {
  favorited: boolean
  following: Follow
  followUser: (follow) => void
  authenticated: boolean
  userID: number
}
export default connect(mapStateToProps, mapDispatchToProps) // Component Calls Connect
