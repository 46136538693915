import React, { FC } from 'react'
import UserPreferencesConnector from 'connectors/UserPreferences'
import { formatDimensionInCm } from './helper'

type DimensionUnits = {
  width: number
  height: number
  depth: number
}

type DimensionsUnitProps = {
  dimensions: DimensionUnits
  userPreferencesMeasurementUnit: 'in' | 'cm'
  isDepthVisible?: boolean
}

const DimensionsUnit: FC<DimensionsUnitProps> = ({
  dimensions,
  userPreferencesMeasurementUnit,
  isDepthVisible = true,
}) => {
  if (!dimensions) {
    return null
  }

  const { width, height, depth } = dimensions

  const depthString = isDepthVisible
    ? ` x ${formatDimensionInCm(
        depth,
        userPreferencesMeasurementUnit
      )} ${userPreferencesMeasurementUnit}`
    : userPreferencesMeasurementUnit

  return (
    <span data-type='dimensions-unit'>
      {`${formatDimensionInCm(width, userPreferencesMeasurementUnit)} x ${formatDimensionInCm(
        height,
        userPreferencesMeasurementUnit
      )} ${depthString}`}
    </span>
  )
}

export default UserPreferencesConnector(DimensionsUnit)
