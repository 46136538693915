import easelAPI, { postOptions, deleteOptions } from 'apis/helpers/easelAPI'
import galleryAPI, { getOptions } from 'apis/helpers/galleryAPI'
import { Follow } from 'types/User/Follow'

export async function fetchFollowApi(): Promise<Follow> {
  const options = getOptions
  const json = await galleryAPI.fetchJSON({
    name: 'fetchFollowApi',
    url: `/follow-user`,
    options,
  })
  return json
}

export async function updateFollowApi(payload: {
  targetUserId: number
  following: boolean
}): Promise<Follow> {
  const { targetUserId, following } = payload
  const options = following ? postOptions : deleteOptions
  const json = await galleryAPI.fetchJSON({
    name: 'updateFollowApi',
    url: `/follow-user/${targetUserId}`,
    options,
  })
  return json
}

// follow api palette endpoint
export async function updateUserFollowApi(payload: {
  targetUserId: number
  following: boolean
  userID: number
}): Promise<Follow> {
  const { targetUserId, following, userID } = payload
  const options = following ? postOptions : deleteOptions
  const json = await easelAPI.fetchJSON({
    name: 'updateUserFollowApi',
    options,
    url: `/user/${userID}/follow-user/${targetUserId}`,
  })
  return json
}
