import React from 'react'
import SALink from 'components/SALink'
import type { ConnectorProps as ArtworkProps } from '../connectors/Artworks'
import ArtworkConnector from '../connectors/Artworks'
import { updateDataLayer } from '../helpers'



const ViewArtworks = ({
  artworksTotal,
  artistID,
}: ArtworkProps): React.ReactElement<React.ComponentProps<any>, any> => {
  return (
    <SALink
      href={`/account/artworks/${artistID}`}
      data-type='view-artwork-button'
      title='view all artworks'
      onClick={() =>
        updateDataLayer({
          event: 'artworks',
        })
      }
    >
      <p className='defaultTypographyCtaLarge'>
        View All {artworksTotal} Artworks
      </p>
    </SALink>
  )
}

export default ArtworkConnector(ViewArtworks)