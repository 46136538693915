import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { Actions as AuthenticationActions } from 'actions/authentication'
import { Actions as SessionActions } from 'actions/user/session'
import { Actions as CartActions } from 'actions/user/cart'
import { Actions as CookieActions } from 'actions/user/cookie'
import { getAuthenticationModal } from 'selectors/authentication'
import { isAuthenticated, isSessionSet } from 'selectors/user/session'
import { Modals } from 'types/Authentication'
import { Actions as ModalActions } from 'actions/shared/modal'
import { getModalName, getModalData } from 'selectors/shared/modal'

const mapStateToProps = createStructuredSelector({
  authenticationModal: getAuthenticationModal,
  isAuthenticated,
  isSessionSet,
  modalData: getModalData,
  name: getModalName,
})

const mapDispatchToProps = (dispatch) => ({
  showModal: (name: Modals) => dispatch(AuthenticationActions.SHOW_MODAL(name)),
  closeModal: () => dispatch(ModalActions.CLOSE_MODAL()),
  openModal: (name: Modals) => dispatch(ModalActions.OPEN_MODAL(name)),
  openModalWithReload: (name: Modals) =>
    dispatch(ModalActions.OPEN_MODAL_WITH_DATA({ name, data: { reloadPage: true } })),
  updateSession: ({ cart, cookie, session }: Record<string, any>) =>
    dispatch([
      CartActions.SET_CART(cart),
      CookieActions.SET_COOKIE(cookie),
      SessionActions.UPDATE_SESSION(session),
    ]),
})

export type ConnectorProps = {
  authenticationModal: Modals
  closeModal: () => void
  isAuthenticated: boolean
  isSessionSet: boolean
  modalData: Record<string, any>
  name: string
  openModal: (name: Modals) => void
  openModalWithReload: (name: Modals) => void
  showModal: (name: Modals) => void
  updateSession: (data: Record<string, any>) => any
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
