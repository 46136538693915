import React from 'react'
import { Radio, Icon, Circle } from './Styles'

type Props = Record<string, any>

export default (props: Props) => {
  const { checked, readOnly, text } = props
  return (
    <Radio readOnly={!!readOnly} data-type='radio'>
      <input type='radio' {...props} />

      <div data-type='radio-text'>
        <Icon>{checked && <Circle checked={!!checked} />}</Icon>

        {text && <p className='defaultTypographyCtaSmall'>{text}</p>}
      </div>
    </Radio>
  )
}
