import React, { useState } from 'react'
import Search from 'components/Search'
import { searchArrayOfObjects } from 'lib/search'
import List from './List'
import CreateButton from './CreateButton'
import { Layout } from './styles'
import CloseIcon from 'svg/close-x.svg'
import classNames from 'classnames'
import styles from './Layout.module.scss'

const Component = ({
  addMessage,
  artworkId,
  artworkList,
  collectionList,
  isSetup,
  handleOpenCreateCollectionsModal,
  containerRef,
  onCloseTooltip,
}: Record<string, any>) => {
  const [search, handleSearch] = useState('')
  return (
    <Layout ref={containerRef}>
      <button
        className={classNames('defaultSvgButton', styles.closeButton)}
        onClick={onCloseTooltip}
      >
        <CloseIcon />
      </button>
      <p data-type='title'>Add to a Collection</p>

      <Search value={search} onChange={handleSearch} />

      {!isSetup ? (
        <div data-type='overlay'>
          <div data-type='loader' />
        </div>
      ) : (
        <List
          addMessage={addMessage}
          artworkId={artworkId}
          collectionList={searchArrayOfObjects(search, collectionList, ['title'])}
          artworkList={artworkList}
        />
      )}

      <CreateButton
        collectionList={collectionList}
        handleOpenCreateCollectionsModal={handleOpenCreateCollectionsModal}
      />
    </Layout>
  )
}

export default Component
