import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { getUserID } from 'selectors/user/session'
import { getUserPreferencesSelectedCurrency } from 'selectors/user/cookie'
import { getFavorites, getAccountUserID, getAccountType } from '../selectors'
const mapStateToProps = createStructuredSelector({
  favorites: getFavorites,
  userID: getAccountUserID,
  sessionUserID: getUserID,
  accountType: getAccountType,
  selectedCurrency: getUserPreferencesSelectedCurrency,
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  favorites: Array<Record<string, any>>
  userID: number
  selectedCurrency: Record<string, any>
  accountType: string
}
export default connect(mapStateToProps, mapDispatchToProps)
