import fetch from 'isomorphic-fetch'
import easelAPI, { postOptions, deleteOptions, EaselResponse } from 'apis/helpers/easelAPI'
import legacyAPI, { createFormData, LegacyResponse } from 'apis/helpers/legacyAPI'

export async function fetchFavoritesApi(): Promise<any> {
  const json = await legacyAPI.fetchJSON({
    name: 'fetchFavoritesApi',
    url: '/account/my-favorites',
  })
  return json
}

export async function updateFavoriteApi(data: {
  id: number
  userId: number
  favorite: boolean
}): Promise<boolean> {
  // const url = `https://www.qa.saatchiart.com/account/favorite`
  const { userId, id, favorite } = data
  const formData = {
    id: `${userId}`,
    id_user_art: `${id}`,
    favorite: `${favorite.toString()}`,
    format: 'json',
  }
  if (favorite) {
    formData['_method'] = '_delete'
  }

  const formOptions = { method: 'POST', body: createFormData(formData) }
  const response = await legacyAPI.fetchResponse({
    formOptions,
    name: 'updateFavoriteApi',
    url: '/account/favorite',
  })
  return response.status === 200
}

export const postMyFavorite = async (artworkId: number): Promise<EaselResponse> => {
  const options = postOptions
  const json = await easelAPI.fetchJSON({
    name: 'postMyFavorite',
    options,
    url: `/my-favorites/${artworkId}`,
  })
  return json
}

export const deleteMyFavorite = async (artworkId: number): Promise<EaselResponse> => {
  const options = deleteOptions
  const json = await easelAPI.fetchJSON({
    name: 'deleteMyFavorite',
    options,
    url: `/my-favorites/${artworkId}`,
  })
  return json
}
