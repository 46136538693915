import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { getIsMobileTraffic } from 'selectors/shared/mobileTraffic'
import { getIsMobileScreenWidth, getScreenIsReady } from 'selectors/screen'
import { getUserPreferencesSelectedCurrency, getAlgoliaUserToken } from 'selectors/user/cookie'
import { getUserID } from 'selectors/user/session'
import { getIndice } from 'selectors/algolia'
import {
  getArtworks,
  getArtworksTotal,
  getFullName,
  getPageParamUserId,
  getAccountUserID,
  getCountry,
} from '../selectors'
const mapStateToProps = createStructuredSelector({
  artistID: getAccountUserID,
  artistName: getFullName,
  artistCountry: getCountry,
  artworks: getArtworks,
  artworksTotal: getArtworksTotal,
  isMobileScreen: getIsMobileScreenWidth,
  isMobileTraffic: getIsMobileTraffic,
  isScreenReady: getScreenIsReady,
  pageParamUserID: getPageParamUserId,
  selectedCurrency: getUserPreferencesSelectedCurrency,
  algoliaUserToken: getAlgoliaUserToken,
  userID: getUserID,
  algoliaSearchIndex: getIndice,
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  artistID: number
  artistCountry: string
  artistName: string
  artworks: Array<Record<string, any>>
  artworksTotal: number
  isMobileScreen: boolean
  isMobileTraffic: boolean
  isScreenReady: boolean
  pageParamUserID: number
  selectedCurrency: Record<string, any>
  algoliaUserToken: string
  userID: number
  algoliaSearchIndex: string
}
export default connect(mapStateToProps, mapDispatchToProps)
