import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import {
  DESKTOP_COLUMN_WIDTH,
  DESKTOP_COLUMN_GAP,
  MOBILE_COLUMN_WIDTH,
  MOBILE_COLUMN_GAP,
} from 'components/Artworks/constants'

export const NoResults = styled.h2`
  color: ${(props) => props.theme.colors.default};
  font-size: 30px;
  font-weight: 400;
  text-align: center;
  width: 100%;
  margin: 50px auto;
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    font-size: 24px;
    margin: 50px auto;
  }
`
// This has a breaking point in trying to LAYOUT items
// If you make the SPANs small say 5px and your GRID hits a HEIGHT of 5000px
// You will not be able to layout and GRID will break
// Basically GRID has a max of 5000 / 5 = 1000 SPANs before grid breaks
export const ArtworksList = styled.div`
  display: grid;
  row-gap: 0;
  column-gap: ${DESKTOP_COLUMN_GAP}px;
  grid-template-columns: repeat(auto-fill, minmax(${DESKTOP_COLUMN_WIDTH}px, 1fr));
  width: 100%;
  overflow: visible;

  figure {
    position: relative;
    margin: 0;
    min-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    break-inside: avoid;
    grid-row-end: span 12;
    margin-bottom: 40px;
    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      margin-bottom: 32px;
      content-visibility: auto;
    }
  }

  [data-type='artwork-image'],
  [data-type='original'],
  [data-type='print'],
  [data-type='artwork-info'] [data-type='main'] {
    width: ${DESKTOP_COLUMN_WIDTH}px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    justify-content: center;
  }

  /* &[data-device='mobile'] { */
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    grid-template-columns: repeat(auto-fill, minmax(${MOBILE_COLUMN_WIDTH}px, 1fr));
    column-gap: ${MOBILE_COLUMN_GAP}px;
    [data-type='artwork-image'],
    [data-type='original'],
    [data-type='print'],
    [data-type='artwork-info'] [data-type='main'] {
      width: ${MOBILE_COLUMN_WIDTH}px;
    }
  }
`
export const ArtworkListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    [data-type='hr'] {
      display: none;
    }
  }
`
