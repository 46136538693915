"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
//@ts-nocheck
const algolia_1 = require("apis/algolia");
let timer;
const DURATION = 6000; // 6 seconds
const MAX_EVENTS = 15;
class AlgoliaEvent {
    constructor() {
        this.clearImpressionEvents = (events) => {
            // We will just grab items IDs and remove them from events fired
            const ids = events.map(({ id }) => id);
            // Remove Existing Items from ARRAY
            this.impressions = this.impressions.filter((event) => !ids.includes(event.id));
            // If we still have TOO MANY items fire off again
            if (this.impressions.length > MAX_EVENTS) {
                this.sendImpressionEvents();
            }
        };
        this.sendImpressionEvents = () => __awaiter(this, void 0, void 0, function* () {
            // Do NOT block page or send EMPTY events
            if (!this.impressions.length) {
                return;
            }
            const cloneImpressions = [...this.impressions.slice(0, MAX_EVENTS)]; // we do NOT want to mutate or touch ORIGINAL source
            const currentImpressionEvents = {
                index: this.algoliaSearchIndex,
                userToken: this.algoliaUserToken,
                authenticatedUserToken: String(this.userID) || '',
                objectIDs: [...cloneImpressions],
                queryID: this.algoliaQueryID || '',
            };
            // Algolia event
            (0, algolia_1.algoliaEventsApi)({
                events: [
                    Object.assign({ eventName: 'view_item_list', eventType: 'view' }, currentImpressionEvents),
                ],
            });
            this.clearImpressionEvents(cloneImpressions);
        });
        this.addImpressionEvent = (artworkId, algoliaSearchIndex, algoliaUserToken, algoliaQueryID, userID) => {
            clearTimeout(timer);
            this.impressions.push(String(artworkId));
            this.algoliaSearchIndex = algoliaSearchIndex;
            this.algoliaUserToken = algoliaUserToken;
            this.algoliaQueryID = algoliaQueryID;
            this.userID = userID;
            timer = setTimeout(this.sendImpressionEvents, DURATION);
            if (this.impressions.length >= MAX_EVENTS) {
                this.sendImpressionEvents();
            }
        };
        // We want a WAY to fire off events when USER closes/leaves page
        this.setupCloseListener = () => {
            if (!this.onCloseHandler) {
                window.addEventListener('beforeunload', () => __awaiter(this, void 0, void 0, function* () {
                    yield this.sendImpressionEvents();
                }));
                this.onCloseHandler = true;
            }
        };
        this.impressions = [];
        this.algoliaSearchIndex = '';
        this.algoliaUserToken = '';
        this.algoliaQueryID = '';
        this.userID = 0;
        this.onCloseHandler = false;
    }
}
const singleton = new AlgoliaEvent();
exports.default = singleton;
