import React, { useRef, useEffect, FC } from 'react'
import useIntersectionObserver from 'hooks/intersectionObserver'

type VisibilityTriggerProps = {
  setShouldLoadComponent: (...args: Array<any>) => any
}

const VisibilityTrigger: FC<VisibilityTriggerProps> = (props) => {
  const { setShouldLoadComponent } = props
  const elementRef = useRef(null)
  const { hasViewed, setIsSetup } = useIntersectionObserver(elementRef)
  useEffect(() => {
    setIsSetup(false)

    if (hasViewed) {
      setShouldLoadComponent(hasViewed)
    }
  }, [hasViewed])
  return <div data-section='reference-line' ref={elementRef} />
}

export default VisibilityTrigger
