import React, { useState } from 'react'
import { displayEllipticalString, updateDataLayer } from '../../helpers'

type Props = {
  aboutArtist: Record<string, any>
}
const TITLE_MAPPING = {
  about: 'Info',
  education: 'Education',
  events: 'Events',
  exhibitions: 'Exhibitions',
}
const MAX_LENGTH = 300

const Component = ({
  aboutArtist,
}: Props): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const aboutArtistKeys = Object.keys(aboutArtist)
  const isDataPresent = Object.values(aboutArtist).some((val) => val)
  const [activeTab, setActiveTab] = useState(0)
  const [showContent, setShowContent] = useState(false)

  const getText = (index, item) => {
    if (index === activeTab) {
      if (!showContent) {
        return displayEllipticalString(aboutArtist[item], MAX_LENGTH)
      } else {
        return aboutArtist[item]
      }
    } else return ''
  }

  if (!isDataPresent) {
    return null
  }

  return (
    <div data-section='user-about-section'>
      {aboutArtistKeys.length > 0 && (
        <>
          <ul data-type='nav-tabs'>
            {aboutArtistKeys
              .filter((item) => Boolean(aboutArtist[item].length))
              .map(
                (
                  item: string,
                  index: number
                ): React.ReactElement<React.ComponentProps<any>, any> => (
                  <li key={item}>
                    <button
                      data-index={`${index === activeTab ? 'active' : ''}`}
                      onClick={() => {
                        updateDataLayer({
                          event: TITLE_MAPPING[item].toLowerCase(),
                        })
                        setActiveTab(index)
                        setShowContent(false)
                      }}
                      type='button'
                      data-type='nav-tab'
                    >
                      <p className='defaultTypographyCtaSmall'>{TITLE_MAPPING[item]}</p>
                    </button>
                  </li>
                )
              )}
          </ul>

          {aboutArtistKeys
            .filter((item) => Boolean(aboutArtist[item].length > 0))
            .map(
              (item: string, index: number): React.ReactElement<React.ComponentProps<any>, any> => (
                <div data-type='info' key={item}>
                  {index === activeTab && (
                    <p
                      className='defaultTypographyMedium'
                      data-type='info-text'
                      dangerouslySetInnerHTML={{
                        __html: `${(getText(index, item) || '').replace(/\n/g, '<br />')}`,
                      }}
                    />
                  )}
                  {index === activeTab && aboutArtist[item].length > MAX_LENGTH && (
                    <button
                      type='button'
                      data-type='view-more-button'
                      onClick={() => {
                        !showContent &&
                          updateDataLayer({
                            event: 'view more',
                            label: TITLE_MAPPING[item].toLowerCase(),
                          })
                        setShowContent(!showContent)
                      }}
                    >
                      <p className='defaultTypographyCtaSmall' style={{ marginTop: '12px' }}>
                        {aboutArtist[item].length > MAX_LENGTH && !showContent
                          ? 'View more'
                          : 'View less'}
                      </p>
                    </button>
                  )}
                </div>
              )
            )}
        </>
      )}
    </div>
  )
}

export default Component
