import React, { useState } from 'react'
import type { ConnectorProps as AppProps } from '../../connectors/App'
import AppConnector from '../../connectors/App'
import SocialIcons from './SocialIcons'
import { ShareIcon } from '../styles'
import { updateDataLayer } from '../../helpers'


import classNames from 'classnames'

const Component = (props: AppProps) => {
  const { fullName, location } = props
  const [toggleIcons, setToggleIcons] = useState(false)
  return (
    <div data-section='share-icons-section'>
      <button
        type='button'
        data-type='share-button'
        onClick={() => {
          updateDataLayer({
            event: 'share',
          })
          setToggleIcons(!toggleIcons)
        }}
        {...(toggleIcons
          ? {
              'data-style': 'visible',
            }
          : {})}
      >
        <ShareIcon />
        <p className={classNames('defaultTypographyCtaSmall', 'defaultTextTruncate')} data-type='share-text'>
          Share
        </p>
      </button>
      {toggleIcons && <SocialIcons fullName={fullName} location={location} />}
    </div>
  )
}

export default AppConnector(Component)