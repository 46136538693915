"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
//@ts-nocheck
const localStorage_1 = require("../../localStorage");
const ALGOLIA_QUERY_ID = 'algolia_query_id';
const EXPIRATION = 1000 * 60 * 60 * 2; // ms * s * m * h => 2 hours
// filterParams( { category: 'all', style: 'abstract' , country: undefined, ... }) => [abstract]
// Returns an Array<string> without `category` => [ 'abstract' ]
const filterParams = (params) => Object.entries(params)
    .reduce((results, [key, value]) => [...results, key !== 'blah' ? value : null], [])
    .filter(Boolean);
// Ex: [ 'fine-art', 'fine-art', 'conceptual', 'impressionism', 'modern', 'conceptual']
// Results ['fine-art', 'conceptual', 'impressionism', 'modern',]
const restrictDuplicateValues = (facetValue) => {
    if (Array.isArray(facetValue)) {
        return facetValue.reduce(
        // if Item Exist, Return Results, else Append Item to Results
        (results, item) => (results.includes(item) ? results : [...results, item]), []);
    }
    // Single Object/String - Do Nothing
    return facetValue;
};
const getAlgoliaQueryIDFromLocalStorage = () => {
    const algoliaQueryIDObject = (0, localStorage_1.get)(ALGOLIA_QUERY_ID);
    if (algoliaQueryIDObject.queryID) {
        return algoliaQueryIDObject.queryID;
    }
    else {
        return '';
    }
};
const setAlgoliaQueryIDInLocalStorage = (queryID) => {
    const futureExpirationUnixTimestamp = new Date().getTime() + EXPIRATION;
    (0, localStorage_1.set)(ALGOLIA_QUERY_ID, { queryID, futureExpirationUnixTimestamp });
};
module.exports = {
    filterParams,
    restrictDuplicateValues,
    getAlgoliaQueryIDFromLocalStorage,
    setAlgoliaQueryIDInLocalStorage,
};
