import React from 'react'
import UserPreferencesConnector from 'connectors/UserPreferences'
import { formatDimensionInCm } from './helper'
import { Dimensions } from './styles'

type DimensionUnits = {
  width: number
  height: number
  depth: number
}
type Props = {
  dimensions: DimensionUnits
  userPreferencesMeasurementUnit: 'in' | 'cm'
  isDepthVisible?: boolean
}

const DimensionsComponent = (props: Props) => {
  if (!props.dimensions) {
    return null
  }

  const {
    dimensions: { width, height, depth },
    userPreferencesMeasurementUnit: measurementUnit,
    isDepthVisible = true,
  } = props

  const depthString = isDepthVisible
    ? ` x ${formatDimensionInCm(depth, measurementUnit)} D ${measurementUnit}`
    : ''

  return (
    <Dimensions data-type='dimensions'>
      {`${formatDimensionInCm(width, measurementUnit)} W x ${formatDimensionInCm(
        height,
        measurementUnit
      )} H${depthString}`}
    </Dimensions>
  )
}

export default UserPreferencesConnector(DimensionsComponent)
