import React, { FC, useState } from 'react'
import dynamic from 'next/dynamic'
import noSSR from 'components/DynamicLoader'
import Modal from 'components/SA/Modal/v2'
import RequestCommissionConnector, {
  ConnectorProps as RequestCommissionProps,
} from '../connectors/RequestCommission'
import { updateDataLayer } from '../helpers'
import { capitalizeString } from 'lib/helpers'

type LaunchModalProps = {
  closeModal: () => void
  artistId: number
  page: string
}

const LaunchModal: FC<LaunchModalProps> = (props) => {
  const CommissionModal = dynamic(
    import(
      /* webpackChunkName: "artistCommissionModal" */
      'components/RequestCommission/CommissionModal'
    ),
    noSSR
  )
  return <CommissionModal {...props} />
}

type RequestCommissionComponentProps = RequestCommissionProps & {
  closeModal: () => void
  openModal: (...args: Array<any>) => any
}

const RequestCommission: FC<RequestCommissionComponentProps> = (props) => {
  const { artistId, fullName, closeModal, canRequestCommission, openModal } = props
  const [showModal, handleShowModal] = useState(false)
  const capitalizedFullName = capitalizeString(fullName)

  if (!canRequestCommission) {
    return null
  }

  return (
    <div data-section='request-commission'>
      <h3 className='defaultTypographyH3' data-type='title'>
        Commission {capitalizedFullName}
      </h3>
      <p className='defaultTypographySmall' data-type='sub-title'>
        If you’re interested in commissioning this artist for a custom artwork, inquire here.
      </p>
      <button
        type='button'
        data-type='req-commission-button'
        onClick={() => {
          updateDataLayer({
            event: 'commission start',
          })
          handleShowModal(true)
          openModal('commissionModal')
        }}
      >
        <p className='defaultTypographyCtaLarge'>Contact Our Curators</p>
      </button>
      {showModal && (
        <Modal name='commissionModal'>
          <LaunchModal closeModal={closeModal} artistId={artistId} page='artist-profile' />
        </Modal>
      )}
    </div>
  )
}

export default RequestCommissionConnector(RequestCommission)
