// eslint-disable-next-line
import React from 'react' // Needed for styled() Extends

import styled from 'styled-components'
import Heart from 'svg/heart.svg'
import Cart from 'svg/bag.svg'
const svgFix = `
  svg:not(:root) {
    overflow: visible;
  }
`
export const HeartIcon = styled(Heart)`
  transition: 0.2s ease-in-out transform;
  width: 20px;
  height: 20px;
  path {
    stroke: #333333;
    stroke-width: 1px;
    @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
      fill: #333333;
      button[data-type='action']:hover & {
        fill: #ef542c;
        stroke: #ef542c;
      }
    }
  }
  &[data-favorite] {
    path {
      fill: #ef542c;
      stroke: #ef542c;
    }
  }
  &[data-favorite='unfavorite'] {
    path {
      fill: #fff;
      stroke: #666;
    }
  }
  ${svgFix}
`
export const CartIcon = styled(Cart)`
  transition: 0.2s ease-in-out transform;
  width: 25px;
  height: 20px;
  button[data-type='action']:hover & {
    path {
      stroke: ${(props) => props.theme.colors.green};
      fill: ${(props) => props.theme.colors.green};
      circle: ${(props) => props.theme.colors.green};
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    path {
      stroke: #333333;
      stroke-width: 1px;
      fill: #333333;
    }
  }
  &[data-style='vote-panel'] {
    path {
      stroke: #333333;
      stroke-width: 1px;
      fill: #333333;
    }
  }
  ${svgFix}
`
export const ActionItem = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0;
  &[data-blocked] {
    pointer-events: none;
    opacity: 0.7;
  }

  &[data-action='collection'] {
    cursor: pointer;
    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      display: none;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    width: 38px;
    height: 37px;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    margin-right: 5px;
  }

  &[data-style='vote-panel'] {
    width: 38px;
    height: 37px;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    margin-right: 5px;
  }
`
export const FavoriteActionItem = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0;
  &[data-blocked] {
    pointer-events: none;
    opacity: 0.7;
  }

  &[data-action='collection'] {
    cursor: pointer;
    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      display: none;
    }
  }

  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: rgb(255, 255, 255);
`
