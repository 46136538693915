import React from 'react'
import withRedux from 'next-redux-wrapper'
import Theme from 'components/Theme'
import parse from 'lib/url/parse'
import { Actions as PageActions } from 'actions/page'
import { App, Store } from 'apps/public/profile/artistProfile'
import { storeSetup } from 'lib/pageSetup'
import { privatePageSetup } from 'lib/reduxStore/privatePageSetup'
import {
  getArtistDataLayer,
  getCuratorDataLayer,
  getMetaData,
} from 'apps/public/profile/artistProfile/selectors'
import { CURATOR } from 'lib/user/constants'

function Page(): React.ReactElement<React.ComponentProps<any>, any> {
  return (
    <Theme>
      <App />
    </Theme>
  )
}

Page.getInitialProps = async ({ req, store, res, query }) => {
  // Client-Side
  if (!req) {
    // Clear out Data
    const storeActions = [] // Collect all Actions

    const { href } = window.location
    // debugger
    storeActions.push(
      PageActions.SET({
        path: parse(href).path || '',
        params: query,
      })
    )
    // Dispatch Array<ACTIONS>
    store.dispatch(storeActions)
    const state = { ...store.getState() }
    const metaData = getMetaData(state)
    return {
      metaData,
    }
  }

  // SSR
  storeSetup(store, req)
  privatePageSetup(store, req)
  const { originalUrl, accountData, accountType } = req
  const pageData = {
    data: {
      accountData,
      accountType,
    },
    name: '/public/profile/artistProfile',
    params: { ...query },
    path: originalUrl,
  }
  // Page Data Setup
  store.dispatch(PageActions.SET(pageData))
  // Akamai Cache Tag
  const { userId } = query
  accountType === CURATOR
    ? res.set(
        'Edge-Cache-Tag',
        `page-curatorProfile, userId-${userId}, page-curatorProfile-${userId}`
      )
    : res.set('Edge-Cache-Tag', `page-userProfile, userId-${userId}, page-userProfile-${userId}`)
  // Metadata & Datalayer
  const state = { ...store.getState() }
  const metaData = getMetaData(state)
  const dataLayer =
    accountType === CURATOR
      ? getCuratorDataLayer(state)
      : getArtistDataLayer(state)
  return {
    dataLayer,
    metaData,
  }
}

export default withRedux(Store)(Page)
