import React from 'react'
import { flagPositions } from 'styles/flags'

type Props = {
  state: string
  city: string
  country: string
  countryCode: string
}

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const { state, city, country, countryCode } = props

  if (!state && !country && !city) {
    return null
  }

  const label = [city, state, country].filter(Boolean).join(', ')
  return (
    <div data-type='user-location-section'>
      {country && (
        <i
          data-type='flag-icon'
          style={flagPositions[(countryCode || '').toLowerCase()]}
          title={country}
        />
      )}
      <p className='defaultTypographySmall' data-type='user-location'>
        {label}
      </p>
    </div>
  )
}

export default Component
