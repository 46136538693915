import React from 'react'
import dynamic from 'next/dynamic'
import noSSR from 'components/DynamicLoader'
import CarouselConnector, { ConnectorProps as CarouselProps } from '../../connectors/Carousels'
import { capitalizeString } from 'lib/helpers'

// We will only show carousel if ARTIST does NOT have the min artworks
const MIN_ARTWORKS = 5

const ArtworkCarousel = ({ selectedCurrency }: Record<string, any>) => {
  const Carousel = dynamic(
    import(
      /* webpackChunkName: "Profile" */
      './Artworks'
    ),
    noSSR
  )
  return <Carousel selectedCurrency={selectedCurrency} />
}

const ArtistCarousel = ({
  artistId,
  artistName,
  isMobile,
  shouldLoadComponent,
}: Record<string, any>) => {
  const Carousel = dynamic(
    import(
      /* webpackChunkName: "Profile" */
      './Artists'
    ),
    noSSR
  )
  const capitalizedArtistName = capitalizeString(artistName)

  return (
    <>
      <Carousel
        artistId={artistId}
        artistName={capitalizedArtistName}
        isMobile={isMobile}
        shouldLoadComponent={shouldLoadComponent}
      />
    </>
  )
}

type Props = CarouselProps & {
  shouldLoadComponent: boolean
}

const Component = (props: Props) => {
  const {
    artworksTotal,
    artistName,
    artistId,
    isArtist,
    isMobile,
    isSessionSet,
    selectedCurrency,
    shouldLoadComponent,
  } = props
  const shouldLoadArtworkCarousel = artworksTotal <= MIN_ARTWORKS
  const capitalizedArtistName = capitalizeString(artistName)

  // Do NOT render anything if user has more than 5 artworks and is an `ARTIST`
  if (!isSessionSet || (!shouldLoadComponent && isArtist)) {
    return null
  }

  return (
    <>
      {shouldLoadArtworkCarousel && <ArtworkCarousel selectedCurrency={selectedCurrency} />}
      {!isArtist && (
        <ArtistCarousel
          artistId={artistId}
          artistName={capitalizedArtistName}
          isMobile={isMobile}
          shouldLoadComponent={shouldLoadComponent}
        />
      )}
    </>
  )
}

export default CarouselConnector(Component)
