//@ts-nocheck
// Lets only pass the PROPS components will NEED
import { ArtworkProps, ImageProps, ImageAltParams } from './Types'
import { capitalizeString } from 'lib/helpers'

type Props = {
  artwork: ArtworkProps
  hasPrints: boolean
}

export const getImageProps = (props: Props): ImageProps => {
  const {
    artwork: {
      artworkId,
      artworkTitle,
      category,
      height,
      // Image HEIGHT
      firstName,
      lastName,
      imageBaseUrl,
      positionID,
      originalStatus,
      listPrice,
      printUrl,
      productUrl,
      sku,
      subject,
      styles,
      width, // Image WIDTH
    },
    hasPrints,
  } = props
  return {
    artistName: `${firstName} ${lastName}`,
    artworkId,
    category,
    dataPosition: positionID || 0,
    hasPrints,
    height,
    // Image HEIGHT
    href: hasPrints ? printUrl : productUrl,
    originalStatus,
    listPrice,
    productType: 'original',
    rel: artworkTitle,
    sku,
    src: imageBaseUrl,
    subject,
    styles,
    title: artworkTitle,
    width, // Image WIDTH
  }
}
// Only the first value of styles is used and if it's the same as subject, uses the second value if exists. Discussed in this ticket: https://saatchiart.atlassian.net/browse/SA-4524
// example of result: `Original Fine Art Abstract Painting by John Doe`
export const getImageAltText = ({
  artistName,
  category,
  subject,
  styles,
}: ImageAltParams): string => {
  const [firstStyleNotMatchingSubject] = (Array.isArray(styles) ? styles : []).filter(
    (style) => (style || '').toLowerCase() !== (subject || '').toLowerCase()
  )
  const capitalizedArtistName = capitalizeString(artistName)
  // Styles is the only param that can be undefined, so we create this helper const to manage spaces needed in the final result
  const styleAndSubjectString = [firstStyleNotMatchingSubject, subject].filter(Boolean).join(' ')
  return `Original ${styleAndSubjectString} ${category} by ${capitalizedArtistName}`
}
// Only the first value of styles is used and if it's the same as subject, uses the second value if exists. Discussed in this ticket: https://saatchiart.atlassian.net/browse/SA-4524
// example of result: `Original Fine Art Abstract Painting by John Doe`
export const getPrintAltText = ({
  artistName,
  category,
  subject,
  styles,
}: ImageAltParams): string => {
  const [firstStyleNotMatchingSubject] = (Array.isArray(styles) ? styles : []).filter(
    (style) => (style || '').toLowerCase() !== (subject || '').toLowerCase()
  )
  const capitalizedArtistName = capitalizeString(artistName)
  // Styles is the only param that can be undefined, so we create this helper const to manage spaces needed in the final result
  const styleAndSubjectString = [firstStyleNotMatchingSubject, subject].filter(Boolean).join(' ')
  return `Print of ${styleAndSubjectString} ${category} by ${capitalizedArtistName}`
}
