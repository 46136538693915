import easelAPI, { EaselResponse, putOptions } from 'apis/helpers/easelAPI'
import { MyStudioArtworksFile } from 'types/Upload'

const stageMyStudioImage = async (imageFile: File): Promise<EaselResponse> => {
  // The file size must be smaller than 5mb
  const megabyte = 1024000 // 1Mb => 1,024,000 bytes
  const megabyteLimit = 5 * megabyte // 5 mb
  if (imageFile.size > megabyteLimit) {
    return {
      success: false,
      payload: {
        errorMessage: 'Your image exceeds the maximum upload size of 5MB.',
      },
    }
  }

  const response = await easelAPI.postFormData({
    url: '/my-studio-art/image/upload',
    data: { image: imageFile },
  })

  if (response.status === 413) {
    return {
      success: false,
      payload: {
        errorMessage: 'Your image is too heavy',
      },
    }
  }

  const json = await easelAPI.parseResponseToJSON(response, 'stageMyStudioImage')

  if (response.status === 400) {
    if (json.message) {
      return {
        success: false,
        payload: {
          errorMessage: json.message,
        },
      }
    }
  }

  if (!json.success) {
    return {
      success: false,
      payload: {},
    }
  }

  return json
}

export const uploadStudioArtworkImage = async (props: Record<string, any>): Promise<void> => {
  const { file, onSuccess, onFailure } = props
  const [data] = file
  const stagingImageApi = await stageMyStudioImage(data)
  const { payload } = stagingImageApi
  const { staging_s3_path: s3key } = payload

  if (s3key) {
    onSuccess({
      data,
      s3key,
    })
  } else {
    onFailure(payload)
  }
}

const putMyStudioImages = async (
  filesArray: Array<Record<string, any>>
): Promise<EaselResponse> => {
  const files: MyStudioArtworksFile = []
  filesArray.forEach((file, index) => {
    const { s3key, description, prefix } = file

    if (file.is_staging) {
      // New
      // is_staging ===1
      files.push({
        staging_s3_path: `/${s3key}`,
        description,
      })
    } else {
      // Existing
      files.push({
        s3_path: prefix,
        description,
      })
    }
  })
  const options = {
    ...putOptions,
    body: JSON.stringify({
      files,
    }),
  }
  const json = await easelAPI.fetchJSON({
    name: 'putMyStudioImages',
    options,
    url: '/my-studio-art/image/save',
  })
  return json
}

export const saveStudioArtworkImages = async (props: Record<string, any>): Promise<void> => {
  const { files, onSuccess, onFailure } = props
  // Math.sign will return 1 if b is greater, 0 if equal, -1 if b is less
  const orderedFiles = files.sort((a, b) => Math.sign(a.order - b.order))
  const json = await putMyStudioImages(orderedFiles)
  if (json.success) {
    onSuccess(json.payload)
  } else {
    onFailure(json)
  }
}
