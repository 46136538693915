"use strict";
//@ts-nocheck
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
let timer;
const DURATION = 6000; // 6 seconds
const MAX_EVENTS = 25;
class DataLayer {
    constructor() {
        this.clearImpressionEvents = (events) => {
            // We will just grab items IDs and remove them from events fired
            const ids = events.map(({ id }) => id);
            // Remove Existing Items from ARRAY
            this.impressions = this.impressions.filter((event) => !ids.includes(event.id));
            // If we still have TOO MANY items fire off again
            if (this.impressions.length > MAX_EVENTS) {
                this.sendImpressionEvents();
            }
        };
        // Normally you will setup/call function once EPICS for USER are setup -> 'NEW_CLIENT_SETUP_COMPLETE'
        this.setCurrency = (currency) => {
            this.currency = currency;
            this.setupCloseListener();
        };
        this.sendImpressionEvents = () => __awaiter(this, void 0, void 0, function* () {
            if (window && window.dataLayer) {
                // Do NOT block page or send EMPTY events
                if (!this.impressions.length) {
                    return;
                }
                const cloneImpressions = [...this.impressions.slice(0, MAX_EVENTS)]; // we do NOT want to mutate or touch ORIGINAL source
                const currentImpressionEvents = {
                    item_list_id: this.itemListId,
                    item_list_name: this.itemListName,
                    items: cloneImpressions.map((impression) => (Object.assign(Object.assign({}, impression), { item_list_id: this.itemListId, item_list_name: this.itemListName }))),
                };
                // Send Events
                // Clear the previous ecommerce object.
                window.dataLayer.push({
                    ecommerce: null,
                });
                window.dataLayer.push({
                    event: 'view_item_list',
                    ecommerce: Object.assign({}, currentImpressionEvents),
                });
                this.clearImpressionEvents(cloneImpressions);
            }
        });
        this.addImpressionEvent = (event, dataListId, dataListName) => {
            clearTimeout(timer);
            this.impressions.push(event);
            this.itemListId = dataListId;
            this.itemListName = dataListName;
            timer = setTimeout(this.sendImpressionEvents, DURATION);
            if (this.impressions.length >= MAX_EVENTS) {
                this.sendImpressionEvents();
            }
        };
        // We want a WAY to fire off events when USER closes/leaves page
        this.setupCloseListener = () => {
            if (!this.onCloseHandler) {
                window.addEventListener('beforeunload', () => __awaiter(this, void 0, void 0, function* () {
                    yield this.sendImpressionEvents();
                }));
                this.onCloseHandler = true;
            }
        };
        this.sendEcommerceEvent = (ecommerceEventParams) => {
            const { isMao, transactionId, value, tax, shipping, coupon, items } = ecommerceEventParams;
            if (window && window.dataLayer) {
                const transformedItems = items.map(({ itemId, itemName, coupon: itemCoupon, discount, itemBrand, itemCategory, price, discountedPrice, quantity, }) => ({
                    item_id: itemId,
                    // SKU
                    item_name: itemName,
                    // artwork title
                    affiliation: 'Online Store',
                    coupon: itemCoupon !== null && itemCoupon !== void 0 ? itemCoupon : 'make_an_offer',
                    discount,
                    // discount amount OR IF MAO, the difference between list price and offer price
                    item_brand: itemBrand,
                    // artist name
                    item_category: itemCategory,
                    // category
                    item_variant: 'original',
                    price,
                    // price before discount
                    discounted_price: discountedPrice,
                    // price AFTER discount; IF MAO, the offer price
                    quantity,
                }));
                window.dataLayer.push({
                    ecommerce: null,
                }); // Clear the previous ecommerce object.
                window.dataLayer.push({
                    event: 'purchase',
                    ecommerce: {
                        transaction_id: transactionId,
                        // US order number
                        value,
                        // [subtotal] - [discount]; IF MAO, the offer price
                        tax,
                        // [US sales tax] + [customs and duties]
                        shipping,
                        // [agent fee] + [crate fee]
                        currency: 'USD',
                        // always USD, must be always passed
                        coupon: isMao ? 'make_an_offer' : coupon,
                        // coupon value OR if MAO "make_an_offer"
                        purchase_source: isMao ? 'mao' : 'cart',
                        // pass this custom property with every purchase event
                        items: transformedItems,
                    },
                });
            }
        };
        // this.currency = 'USD'
        this.itemListId = '';
        this.itemListName = '';
        this.impressions = [];
        this.onCloseHandler = false;
    }
}
const singleton = new DataLayer();
exports.default = singleton;
