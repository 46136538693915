import React, { useState, useEffect } from 'react'

type Props = {
  key: string | number,
  thumbnailUrl: string,
  description?: string
}

const Component = ({ thumbnailUrl, description, key }: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const [imageDimensions, setImageDimensions] = useState<{ width?: number, height?: number }>({})

  useEffect(() => {
    const img = new Image();
    img.src = thumbnailUrl;

    img.onload = () => {
      setImageDimensions({
        height: img.height,
        width: img.width
      })
    }
  }, [thumbnailUrl]);

  return (
    <div data-type='image'>
      <img
        key={`image-detail-${key}`}
        src={thumbnailUrl}
        alt={`Slide ${key}`}
        itemProp='image'
        width={imageDimensions.width}
        height={imageDimensions.height}
      />
      {description && <p> {description} </p>}
    </div>
  )
}

export default Component
