import React, { useRef } from 'react'
import { useCarousel } from 'hooks/carousel'
import MobileSwipe from 'components/MobileSwipe'
import BackIcon from 'svg/caret-left.svg'
import NextIcon from 'svg/caret-right.svg'
import InsideTheStudioImage from './InsideTheStudioImage'
import { Gallery } from '../styles'

type Props = {
  studioImages: Array<{
    thumbnailUrl: string,
    description?: string
  }>
}

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const { studioImages } = props
  const { index, setIndex, prev, next } = useCarousel(studioImages.length) //  next, prev,

  const galleryRef: Record<string, any> = useRef()
  const element = galleryRef?.current?.getBoundingClientRect?.()?.toJSON?.() || {}
  const elementWidth = element.width || 0
  const offset = elementWidth * index
  return (
    <div data-section='image-gallery'>
      {studioImages.length > 1 && (
        <div data-section='controls'>
          <BackIcon data-type='back' onClick={prev} />
          <NextIcon data-type='next' onClick={next} />
        </div>
      )}

      <Gallery data-section='gallery' ref={galleryRef}>
        <MobileSwipe
          minimumDragDistance={50}
          onSwipeComplete={(direction) => {
            direction === 'next' ? next() : prev()
          }}
        />

        <div
          data-section='images'
          style={{
            transform: `translateX(-${offset}px)`,
          }}
        >
          {studioImages.map(({ thumbnailUrl, description }, key) => (
            <InsideTheStudioImage
              key={key}
              thumbnailUrl={thumbnailUrl}
              description={description}
            />
          ))}
        </div>
      </Gallery>

      {studioImages.length > 1 && (
        <div data-section='dots'>
          {studioImages.map((data, key) => (
            <button
              data-type='dot'
              type='button'
              onClick={() => setIndex(key)}
              {...(index === key
                ? {
                    'data-style': 'active',
                  }
                : {})}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default Component
