import React, { useState } from 'react'
import { addStoreLocaleByCookie } from 'lib/storeLocale/client'
import { updateUserFollowApi } from 'apis/private/follow'
import { Follow } from './styles'
import AuthenticationConnect, {
  ConnectorProps as AuthenticationProps,
} from 'connectors/Authentication'

type Props = AuthenticationProps & {
  artistId: number
  artistFullName: string
  isArtistFollowed: boolean
  followedUserArray: Array<number>
  setFollowedUserArray: (...args: Array<any>) => any
  authenticated: boolean
  userID: number
}

const updateFollow = async ({
  userID,
  targetUserId,
  authenticated,
  following,
  callBack,
  openModal,
}: Record<string, any>) => {
  if (!authenticated) {
    openModal('login')
    // const { pathname, search } = window.location
    // const redirectURL = encodeURIComponent(`${pathname || '/'}${search || ''}`)
    // const location = addStoreLocaleByCookie(`/authentication?redirect_url=${redirectURL}`)
    // window.location = location
    // return
  }

  const apiResponse = await updateUserFollowApi({
    userID,
    targetUserId,
    following,
  })

  if (apiResponse) {
    callBack(targetUserId)
  }
}

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const {
    artistId,
    artistFullName,
    authenticated,
    isArtistFollowed,
    followedUserArray,
    setFollowedUserArray,
    userID,
    openModal,
  } = props
  const [blockEvents, setBlockEvents] = useState(false)
  return (
    <Follow
      type='button'
      title={!isArtistFollowed ? `Follow ${artistFullName}` : `Unfollow ${artistFullName}`}
      data-type='follow-button'
      {...(isArtistFollowed
        ? {
            'data-style': 'following',
          }
        : {})}
      {...(blockEvents
        ? {
            'data-blocked': true,
          }
        : {})}
      onClick={(ev: React.SyntheticEvent<Record<string, any>>) => {
        if (blockEvents) {
          return
        }

        setBlockEvents(true)
        ev.preventDefault()

        if (!isArtistFollowed && window && window.dataLayer) {
          window.dataLayer.push({
            event: 'follow',
          })
        }

        updateFollow({
          userID,
          targetUserId: artistId,
          authenticated,
          openModal,
          following: !isArtistFollowed,
          callBack: (followID: number) => {
            setBlockEvents(false)

            if (window && window.dataLayer) {
              if (!isArtistFollowed) {
                window.dataLayer.push({
                  event: 'follow success',
                })
              } else {
                window.dataLayer.push({
                  event: 'unfollow',
                })
              }
            }

            isArtistFollowed
              ? setFollowedUserArray(followedUserArray.filter((item) => item !== artistId))
              : setFollowedUserArray([...followedUserArray, artistId])
          },
        })
      }}
    >
      <span>
        <p className='defaultTypographyCtaMedium' data-type='follow-text'>
          {isArtistFollowed ? 'Following' : 'Follow'}
        </p>
      </span>
    </Follow>
  )
}

export default AuthenticationConnect(Component)
