import React, { FC } from 'react'
import FlashMessagesConnector, {
  ConnectorProps as FlashMessageProps,
} from 'containers/FlashMessage'
import { Uploader as ArtworkUploader } from 'components/Uploader'
import SortableList from 'components/Artworks/UploadAdditionalImages/SortableList'
import useHook from 'components/Artworks/UploadAdditionalImages/hook'
import { UploadAdditionalImage } from 'components/Artworks/UploadAdditionalImages/styles'
import { uploadStudioArtworkImage, saveStudioArtworkImages } from 'apis/private/uploadStudioArtwork'

type Props = FlashMessageProps & {
  setShowUploader: (...args: Array<any>) => any
  setStudioImages: (...args: Array<any>) => any
  additionalImages: Array<Record<string, any>>
}

// reusing Sortable List, hook, styles from additional images up-loader
const Component: FC<Props> = (props) => {
  const { additionalImages, addMessage, setShowUploader, setStudioImages } = props
  const { dispatch, onDelete, onSortDown, onSortUp, state, updateDescription } = useHook({
    additionalImages,
  })
  const { files, submitting, uploadId, uploading } = state
  let disabledText = ''

  if (files.length >= 5) {
    disabledText = 'You have reached the max limit of 5 images.'
  } else if (uploading) {
    disabledText = 'Uploading Image'
  }

  return (
    <UploadAdditionalImage data-section='additional-images'>
      <p data-type='upload-callout'>
        Uploading your studio images gives collectors a better sense of you as an artist. Consider
        sharing images such as you in action in your studio, close-ups of materials,
        work-in-progress, and more.
      </p>

      <SortableList
        files={files}
        actions={{
          onSortUp,
          onSortDown,
          onDelete,
          updateDescription,
        }}
        app='artist-profile'
      />

      <ArtworkUploader
        onReceiveFile={(file) => {
          dispatch({
            uploading: true,
          })
          uploadStudioArtworkImage({
            file,
            onSuccess: ({ data, s3key }) => {
              const newFile = {
                s3key,
                description: null,
                is_staging: 1,
                data,
                order: files.length,
                id: uploadId + 1,
                new: true,
              }
              dispatch({
                files: files.length ? [...files, newFile] : [newFile],
                uploadId: uploadId + 1,
                uploading: false,
              })
            },
            onFailure: (payload) => {
              const { errorMessage } = payload
              dispatch({
                uploading: false,
              })
              addMessage({
                messages: [errorMessage || 'Problem uploading image'],
                type: 'error',
              })
            },
          })
        }}
        disabled={files.length >= 5 || submitting || uploading}
        disabledText={disabledText}
      />

      <div data-section='buttons'>
        <button
          type='button'
          onClick={() => {
            dispatch({
              submitting: true,
            })
            saveStudioArtworkImages({
              files,
              onSuccess: (payload) => {
                setStudioImages(payload)
                dispatch({
                  submitting: false,
                })
                addMessage({
                  messages: ['You have successfully saved your image.'],
                  type: 'success',
                })
                setShowUploader(false)
              },
              onFailure: (json) => {
                const { message } = json
                dispatch({
                  submitting: false,
                })
                addMessage({
                  messages: [message || 'Problem uploading image'],
                  type: 'error',
                })
              },
            })
          }}
        >
          {submitting ? 'Saving...' : 'Save'}
        </button>
      </div>
    </UploadAdditionalImage>
  )
}

export default FlashMessagesConnector(Component)
