import React, { FC, ReactNode, useEffect, useState } from 'react'
import styles from './Modal.module.scss'
import CloseIcon from 'svg/close-x.svg'
import classNames from 'classnames'

type ModalProps = {
  onClose?: () => void
}

type SAModalComponentProps = {
  children: ReactNode | undefined
  contentStylesClassName?: string
}

export function useSAModal({ onClose }: ModalProps = {}) {
  const [isOpen, setIsOpen] = useState(false)

  const handleOpenModal = () => setIsOpen(true)

  const handleCloseModal = () => {
    setIsOpen(false)
    onClose?.()
  }

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto'

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  const SAModal: FC<SAModalComponentProps> = ({ children, contentStylesClassName = '' }) => {
    if (!isOpen) {
      return null
    }

    return (
      <div className={styles.modalOverlay} onClick={handleCloseModal}>
        <div
          className={classNames(styles.modalContent, {
            [contentStylesClassName]: contentStylesClassName !== '',
          })}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.modalClose}>
            <CloseIcon onClick={handleCloseModal} className={styles.modalCloseIcon} />
          </div>
          {children}
        </div>
      </div>
    )
  }

  return { SAModal, isOpen, handleOpenModal, handleCloseModal }
}
