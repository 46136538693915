import easelAPI, { EaselResponse } from 'apis/helpers/easelAPI'

export const uploadHeroImage = async (imageFile: File): Promise<EaselResponse> => {
  const response = await easelAPI.postFormData({
    url: '/my-hero-image/upload',
    data: { image: imageFile },
  })

  const json = await easelAPI.parseResponseToValidateLoginJSON(response, 'uploadHeroImage')

  if (response.status === 400) {
    if (json.message) {
      return {
        success: false,
        payload: {
          errorMessage: json.message,
        },
      }
    }
  }

  if (!json.success) {
    return {
      success: false,
      payload: {},
    }
  }

  return json
}
