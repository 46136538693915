import React, { useState, useEffect } from 'react'
import type { ConnectorProps as FollowProps } from 'containers/Follow'
import FollowConnector from 'containers/Follow'
import { fetchFollowApi } from 'apis/private/follow'
import Follow from './Follow'
type Props = FollowProps & {
  artistID: number
  artistFullName: string
}

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const { authenticated, userID, artistID, artistFullName } = props
  const [followedUserArray, setFollowedUserArray] = useState([])

  // fetch call to get array of follow IDs
  const fetchData = async () => {
    const result = await fetchFollowApi()
    setFollowedUserArray(result)
  }

  useEffect(() => {
    if (authenticated) {
      fetchData()
    }
  }, [authenticated])
  const isArtistFollowed =
    authenticated && Array.isArray(followedUserArray) && followedUserArray.includes(artistID)
  return (
    <Follow
      artistId={artistID}
      artistFullName={artistFullName}
      authenticated={authenticated}
      isArtistFollowed={isArtistFollowed}
      followedUserArray={followedUserArray}
      setFollowedUserArray={setFollowedUserArray}
      userID={userID}
    />
  )
}

export default FollowConnector(Component)
