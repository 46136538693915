import easelAPI, { EaselResponse } from 'apis/helpers/easelAPI'

export const uploadAvatarImage = async (imageFile: File): Promise<EaselResponse> => {
  // the file size must be smaller than 10mb
  const megabyte = 1024000 // 1Mb => 1,024,000 bytes
  const megabyteLimit = 10 * megabyte // 10 mb
  if (imageFile.size > megabyteLimit) {
    return {
      success: false,
      payload: {
        errorMessage: 'Your image exceeds the maximum upload size of 10MB.',
      },
    }
  }

  const response = await easelAPI.postFormData({
    url: '/my-avatar-image/upload',
    data: { image: imageFile },
  })

  const json = await easelAPI.parseResponseToValidateLoginJSON(response, 'uploadAvatarImage')

  if (response.status === 400) {
    if (json.message) {
      return {
        success: false,
        payload: {
          errorMessage: json.message,
        },
      }
    }
  }

  if (!json.success) {
    return {
      success: false,
      payload: {},
    }
  }

  return json
}
