import React from 'react'
import SALink from 'components/SALink'
import { ArtworksList } from 'components/Artworks/styles/list'
import ArtworkConnector, { ConnectorProps as ArtworkProps } from '../../connectors/Artworks'
import Artwork from './Artwork'
import NoResults from './NoResults'
import { updateDataLayer } from '../../helpers'
import { capitalizeString } from 'lib/helpers'

const Component = (props: ArtworkProps) => {
  const {
    artistID,
    artworks,
    artworksTotal,
    artistName,
    artistCountry,
    isMobileScreen,
    isMobileTraffic,
    isScreenReady,
    selectedCurrency,
    algoliaUserToken,
    userID,
    algoliaSearchIndex,
  } = props
  const capitalizedArtistName = capitalizeString(artistName)
  const isMobile = isMobileScreen && isScreenReady
  // PDPs to open in new tabs when in *Desktop* view (NON mobile devices)
  const shouldOpenNewDesktopPdpTab = !isMobileTraffic
  return (
    <div data-section='artworks'>
      {!artworks.length ? (
        <NoResults />
      ) : (
        <>
          <h4 className='defaultTypographyH4' data-type='main-title'>
            Artworks
          </h4>
          <ArtworksList data-device={isMobile ? 'mobile' : 'desktop'} data-style='artist-artwork'>
            {artworks.map((artwork, index) => {
              const priority = index === 0
              const lazyLoad = index > 3
              return (
                <Artwork
                  artwork={artwork}
                  artistCountry={artistCountry}
                  artistName={capitalizedArtistName}
                  isMobile={isMobile}
                  key={`artwork-${index}`}
                  lazyLoad={lazyLoad}
                  priority={priority}
                  dataPosition={index}
                  selectedCurrency={selectedCurrency}
                  shouldOpenNewDesktopPdpTab={shouldOpenNewDesktopPdpTab}
                  algoliaUserToken={algoliaUserToken}
                  userID={userID}
                  algoliaSearchIndex={algoliaSearchIndex}
                />
              )
            })}
          </ArtworksList>
          <SALink
            href={`/account/artworks/${artistID}`}
            data-type='view-all'
            title='view all artworks'
            onClick={() =>
              updateDataLayer({
                event: 'artworks',
              })
            }
          >
            <p className='defaultTypographyCtaLarge'>View All {artworksTotal} Artworks</p>
          </SALink>
        </>
      )}
    </div>
  )
}

export default ArtworkConnector(Component)
