import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import { lighten } from 'polished'
import Plus from 'svg/plus-rounded-corners.svg'
import Tick from 'svg/check-circle.svg'
const Loader = `
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 2s linear infinite;
`
export const PlusIcon = styled(Plus)`
  width: 20px;
  height: 20px;
  fill: #333;
  margin-top: 5px;
  &:hover {
    fill: ${(props) => props.theme.colors.blue};
  }
`
export const TickIcon = styled(Tick)`
  width: 16px;
  height: 16px;
  fill: ${(props) => props.theme.colors.blue};
`
export const Title = styled.p`
  display: inline-block;
  margin: 0;
  ${(prop) => prop.error && `color: red;`};
  font-size: ${(props) => props.theme.fontSizes[4]}px;
  font-weight: bold;
`
export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const List = styled.div``
export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: ${(props) => props.theme.space[2]}px;
  background-color: white;

  [data-type='title'] {
    color: #1e1e1e;
    font-size: 18px;
    font-weight: bold;
    margin: 5px 0 10px;
  }

  div[data-type='overlay'] {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    div[data-type='loader'] {
      ${Loader};
      width: 30px;
      height: 30px;
      margin: 20px auto;
    }
  }

  [data-item='collection-list'] {
    max-height: 250px;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-bottom: 10px;

    [data-type='no-results'] {
      margin: 20px auto;
      text-align: center;
      font-size: 21px;
    }

    [data-type='collection-item'] {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f9f9f9;
      border: 1px solid #ebebeb;
      margin: 0;
      padding: ${(props) => props.theme.space[2]}px;
      transition: 0.5s ease all;
      + div {
        border-top: none;
      }
      p {
        font-size: ${(props) => props.theme.fontSizes[2]}px;
        color: ${(props) => props.theme.colors.blue};
        margin: 0;
        padding: 0;
        flex: 1;
        text-align: left;
      }

      i {
        font-size: ${(props) => props.theme.fontSizes[3]}px;
        color: ${(props) => props.theme.colors.blue};
      }

      [data-type='loader'] {
        ${Loader};
        margin-right: 10px;
        border-width: 3px;
      }

      &[data-selected] {
        background-color: #dff0d8;
      }

      &:hover {
        background-color: #f5f5f5;
        &[data-selected] {
          background-color: ${lighten(0.05, '#dff0d8')};
        }
      }
    }
  }

  [type='button'] {
    cursor: pointer;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background-color: #333333;
    color: rgb(255, 255, 255);
    border: 1px solid transparent;
    transition: background-color 0.3s ease 0s, border-color 0.3s ease 0s;
    will-change: background-color;
    &:hover {
      background-color: #000000;
    }
  }
`
